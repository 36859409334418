import { useEffect, useState } from "react";
import _ from "lodash";

export default function HeadingAnchor ({ headings, hasChildren }) {

    const [headingText, setHeadingText] = useState('');
    const [topAdj, setTopAdj] = useState(0)

    useEffect(() => {
        var doc = document.documentElement;
        setTopAdj(doc.clientHeight)
    }, [headings])

    const displaySceneBeatOption = (e, heading, index) => {
        e.preventDefault()
        let headings = document.querySelectorAll('h1.pre');
        if (headings[index]) {
            headings[index].classList.add('active')
            document.querySelector("html").scrollTop = headings[index].offsetTop - 50;
            setTimeout(() => {
                headings[index].classList.remove('active')
            }, 1000);
        }
    }

    return (
        <div id="heading_anchor" className="position-fixed d-none d-lg-block" style={{ right: hasChildren ? '34%' : '100px' }}>
            {headings.length != 0 && [...headings].map((heading, index) => (
                <div key={index} 
                    onClick={(e) => displaySceneBeatOption(e, heading, index, heading.newTop)} 
                    className="heading_edit cursor-pointer" 
                    style={{ top: ((topAdj / headings.length) > 20) ? 20  * (index + 1) : ((topAdj / headings.length) > 20) * (index + 1) +'px' }}
                >
                    <span data-linetitle={heading.data.innerText} className="line_animation_expand"></span>
                </div>
            ))}
        </div>
    );
}