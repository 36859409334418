import React, { useEffect, useState } from "react";
import { Link, useOutletContext } from "react-router-dom";
import { getShowTellData } from "../../services/reportServices";
import {
  sanitizeResetQuillAndGetContent,
  highlightTextBlast,
  highlightTextByIndex,
  quillContentInsertBlotByContentIndex,
} from "../../services/highlightContent";
import _ from "lodash";
import { generateNotification } from "../../services/notificationService";
import Card2 from "../common/Cards/Card2";
import Quill from "../../utils/Quill";

export default function ShowTell() {
    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage, 
        setAiResultOutput, 
        setShowAIResultPopup,
        handleBackActivity
    ] = useOutletContext();

    const [splashLoader, setSplashLoader] = useState(true);

    useEffect(() => {
        setReport({});
        setCheckPage('show and tell');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        if (quill && quill.getLength() > 3) {
            loadShowTell();
        }
    }, [propsRouter]);

    const handleClickCallBack = (e) => {
        var hoverElement = document.getElementById('suggestionHover');
        hoverElement.innerHTML = '';
        var html = "<div>"+
        "<p class='mb-1'>Recommendation: </p>"+
        "<small class='d-block fw-normal'>You can use <span class='fw-semibold'>`Paraphrase`</span> or <span class='fw-semibold'>`AI Rewrite`</span> to improve your content if necessarry.</small>"+
        "</div>";
        hoverElement.innerHTML = html;

        var temp_left = 0, temp_top = 0;

        if (e.target.offsetWidth / 2 < e.target.offsetLeft) temp_left = e.target.offsetLeft - e.target.offsetWidth / 2;

        hoverElement.style.left = (e.target.offsetWidth / 2 + e.target.offsetLeft - temp_left) + 'px';
        hoverElement.style.top = (e.target.offsetTop + e.target.offsetHeight + temp_top + 100) + 'px';
        hoverElement.style.display = 'block';
    }

    const loadShowTell = async () => {
        setSplashLoader(true);
        setTimeout(async () => {
            try {
                if (userData.packages[0] && userData.user) {
                    let quillContent = await sanitizeResetQuillAndGetContent(quill);
                    let images = document.querySelectorAll('.ql-editor img');
                    for (const image of images) {
                      let blot = await Quill.find(image);
                      let index = blot.offset(quill.scroll);
                      quillContent = quillContent.slice(0, index) + ' ' + quillContent.slice(index)
                    }

                    let showTellData = await getShowTellData(quillContent);

                    let showPara = showTellData.showPara;
                    let tellPara = showTellData.tellPara;
                    showTellData = showTellData.result;

                    let showing = showTellData.filter((sentence) => sentence.type == 'showing')
                    let telling = showTellData.filter((sentence) => sentence.type == 'telling')

                    setReport({
                        lastReportIndexes: {
                            start: 0,
                            end: quill.getLength(),
                        },
                        data: {
                            keywordsData: showTellData,
                            showing: showing,
                            telling: telling,
                            showPara: showPara,
                            tellPara: tellPara,
                        },
                    });

                    var elements = document.getElementsByClassName("telling");

                    for (let index = 0; index < elements.length; index++) {
                        elements[index].removeEventListener('click', handleClickCallBack)
                    }

                    window.$(".telling").unbind("click");

                    quillContentInsertBlotByContentIndex(quill, 0, showing, "sentenceStartIndex", "sentenceEndIndex", [{
                        blotName: "span",
                        id: `sentence-{index}`,
                        classes: [`showing`, `sentence-{sentence}`],
                        classDynamicKeys: [ "", { replaceBy: "{sentence}", replaceToKey: "sentence" } ],
                    }]);

                    quillContentInsertBlotByContentIndex(quill, 0, telling, "sentenceStartIndex", "sentenceEndIndex", [{
                        blotName: "span",
                        id: `sentence-{index}`,
                        classes: [`telling`, `sentence-{sentence}`],
                        classDynamicKeys: [ "", { replaceBy: "{sentence}", replaceToKey: "sentence" } ],
                    }]);

                    var elements = document.getElementsByClassName("telling");

                    for (let index = 0; index < elements.length; index++) {
                        elements[index].addEventListener('click', handleClickCallBack)
                    }

                    setSplashLoader(false);
                } else {
                    setSplashLoader(false);
                    generateNotification({
                        title: "Account Expired!",
                        text: "Please check your account for the payment, This report is currently not available.",
                        icon: "error",
                    });
                }
            } catch (error) {
                setSplashLoader(false);
                generateNotification({
                    title: "Network Error!",
                    text: "Unable to process content, Please try again later.",
                    icon: "error",
                });
            }
        });
    };

    let keywordsData = [];
    let showing = [];
    let telling = [];
    let showPara = [];
    let tellPara = [];
    let showingPer = 0;
    let tellingPer = 0;

    if (report?.data) {
        keywordsData = report.data.keywordsData || [];
        showing = report.data.showing || [];
        telling = report.data.telling || [];
        showPara = report.data.showPara || [];
        tellPara = report.data.tellPara || [];
        showingPer = showing.length || 0;
        tellingPer = telling.length || 0;
        showingPer = _.round((showing.length / (showing.length + telling.length)) * 100);
        tellingPer = _.round((telling.length / (showing.length + telling.length)) * 100);
    }

    return (
        <div className="result_cover">
            <div className="top_nav">
                <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                    <Link data-title="Back To Editor" onClick={(e) => handleBackActivity(e)}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></Link>
                    <span className="fw-medium fs-14px">Show and Tell</span>
                    <a className="text-dark fs-10px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/showing-vs-telling-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
                    <span className="ms-auto">
                        <button onClick={loadShowTell} className="btn theme-btn4 fs-10px">Refresh</button>
                    </span>
                </div>
            </div>
            <div className="result_card_cover text-start">
                <div className="reportContainer me-2 mt-0">
                    {!splashLoader && keywordsData.length == 0 && 
                        <Card2 title="Report Warning" description="Unable to fetch content, Please Refresh the report"></Card2>
                    }
                    {splashLoader && keywordsData.length == 0 && 
                        <>
                            <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                            <div className="card__animate mb-2"></div>
                            <div className="card__animate_mini mb-2"></div>
                            <div className="card__animate_mini"></div>
                        </>
                    }
                    {showing.length >= 0 && telling.length >= 0 && 
                        <div className="card mb-3 custom-card1">
                            <div className="card-body">
                                <p className="fs-14px fw-medium mb-1" htmlFor="showTellRation">Show vs Tell Ratio</p>
                                <label className="fs-12px" htmlFor="showTellRation">Show<span className="ms-2">{showingPer}%</span></label>
                                <div className="progress bg-light my-2" style={{ height: '3px' }}>
                                    <div className="progress-bar success-bar" role="progressbar" style={{ width: `${showingPer}%` }} aria-valuenow={`${showingPer}`} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <label className="fs-12px" htmlFor="showTellRation">Tell<span className="ms-2">{tellingPer}%</span></label>
                                <div className="progress bg-light my-2" style={{ height: '3px' }}>
                                    <div className="progress-bar warning-bar" role="progressbar" style={{ width: `${tellingPer}%` }} aria-valuenow={`${tellingPer}`} aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    }
                    {keywordsData && keywordsData.map((v, i) => (
                        <div key={i} className={`card fs-12px cursor-pointer text-start card-adv custom-card1 mb-3 p-0 reportcard-1 sentence-${_.kebabCase(v.sentence)}`} onClick={(e) => highlightTextByIndex(e, `sentence-${_.kebabCase(v.sentence)}`)}>
                            <div className="card-body">
                                <input className="form-check-input" id={`sentence-${_.kebabCase(v.sentence)}-cb`} type="checkbox" />
                                <div className="d-flex align-items-center">
                                    <div>{v.sentence && _.truncate(v.sentence, { lenght: 100 })}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
