import React, { useEffect, useRef, useState } from "react";
import Card2 from "../common/Cards/Card2";
import _ from "lodash";
import { sanitizeResetQuillAndGetContent } from "../../services/highlightContent";
import { useReactToPrint } from 'react-to-print';
import { getSentencesDialogue, getSentenceStarterConjunctionReport, getSentenceStarterIngReport, getSentenceStarterNounReport, getSentenceStarterPronounReport, getSentenceStarterReport, getSentenceVariation, getSummaryReportData } from "../../services/reportServices";
import DialogueTagsGraph from "../graph/DialogueTagsGraph";
import VariationGraph from "../graph/VariationGraph";
import PowerWordsGraph from "../graph/PowerWordsGraph";
import InitialsGraph from "../graph/InitialsGraph";
import SentenceDialogueGraph from "../graph/SentenceDialogueGraph";
import WordCloudGraph from "../graph/WordCloudGraph";

export default function SummaryReportFullPage({ docId, report, setReport, quill, setQuill, userData, setUserData, summaryReportPopup, toggleSummaryReport, checkPage, setCheckPage }) {

    const [loader, setLoader] = useState(true);
    const [errMsg, setErrMsg] = useState([]);
    const componentRef = useRef();

    useEffect(() => {
        setReport({});
        setCheckPage('summary');
        if (quill && quill.getLength() > 3) {
          loadSummaryReport();
        }
    }, [])

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Summary Report',
        onAfterPrint: () => console.log('Printed PDF Successfully!'),
    });

    const loadSummaryReport = () => {
        setLoader(true);
        try {
            setTimeout(async () => {
                const quillContent = await sanitizeResetQuillAndGetContent(quill);

                if (quillContent.length <= 4000) {
                    setLoader(false);
                    setErrMsg([{ title: "Content Limit!", description: "You will need more than 4000 characters to process this report, Please try again later!" }]);
                } else {
                    var user = { id: userData.user.id, email: userData.user.email };
                    let sentenceVariationResult = getSentenceVariation(quillContent);
                    let response = await getSummaryReportData(quillContent, user, sentenceVariationResult.result.length, sentenceVariationResult.totalWordCount);
                    let sentenceDialogue = await getSentencesDialogue(quillContent);
                    sentenceVariationResult = sentenceVariationResult.result;
                    let conjunctionStarter = getSentenceStarterConjunctionReport(quillContent);
                    conjunctionStarter = conjunctionStarter.result;
                    let ingStarter = await getSentenceStarterIngReport(quillContent);
                    let pronStarter = await getSentenceStarterPronounReport(quillContent);
                    let nounStarter = await getSentenceStarterNounReport(quillContent);
                    let sentenceStarter = await getSentenceStarterReport(quillContent);

                    let passiveIndicators = response[0].data.data;
                    let dialogueTags = response[1].data.data;
                    let paragraphVariation = response[2].data.response;
                    let pacingCount = response[3].data.response;
                    let textReadability = response[4].data.response;

                    let encouragePowerWords = response[5].data.data;
                    let angerPowerWords = response[6].data.data;
                    let energeticPowerWords = response[7].data.data;
                    let fearPowerWords = response[8].data.data;
                    let forbiddenPowerWords = response[9].data.data;
                    let generalPowerWords = response[10].data.data;
                    let greedPowerWords = response[11].data.data;
                    let lustPowerWords = response[12].data.data;
                    let safetyPowerWords = response[13].data.data;

                    let wordFrequency = response[14].data.data;
                    let cliches = response[15].data.data;

                    cliches = _.map(_.groupBy(cliches, "word"), (dataArr, word) => { return { word: word, wordsCount: dataArr.length } });
                    cliches = _.orderBy(cliches, ["wordsCount"], ["desc"]);
                    cliches = cliches.slice(0, 10);

                    const sentenceCount = sentenceVariationResult.length;

                    let sentenceVariationDataReport = [
                        {'variant': '< 10', 'value': sentenceVariationResult.filter(d => (d.wordsCount < 10 && d.wordsCount != 0))},
                        {'variant': '10-19', 'value': sentenceVariationResult.filter(d => (d.wordsCount >= 10 && d.wordsCount <= 19))},
                        {'variant': '20-29', 'value': sentenceVariationResult.filter(d => (d.wordsCount >= 20 && d.wordsCount <= 29))},
                        {'variant': '30-39', 'value': sentenceVariationResult.filter(d => (d.wordsCount >= 30 && d.wordsCount <= 39))},
                        {'variant': '> 40', 'value': sentenceVariationResult.filter(d => (d.wordsCount >= 40))},
                    ];

                    let paragraphVariationDataReport = [
                        {'variant': '< 25', 'value': paragraphVariation.filter(d => (d.wordsCount < 25 && d.wordsCount != 0))},
                        {'variant': '25-49', 'value': paragraphVariation.filter(d => (d.wordsCount >= 25 && d.wordsCount <= 49))},
                        {'variant': '50-74', 'value': paragraphVariation.filter(d => (d.wordsCount >= 50 && d.wordsCount <= 74))},
                        {'variant': '75-99', 'value': paragraphVariation.filter(d => (d.wordsCount >= 75 && d.wordsCount <= 99))},
                        {'variant': '> 100', 'value': paragraphVariation.filter(d => (d.wordsCount >= 100))},
                    ];

                    let powerReport = [];
                    powerReport['encourage'] = encouragePowerWords;
                    powerReport['anger'] = angerPowerWords;
                    powerReport['energetic'] = energeticPowerWords;
                    powerReport['fear'] = fearPowerWords;
                    powerReport['forbidden'] = forbiddenPowerWords;
                    powerReport['general'] = generalPowerWords;
                    powerReport['greed'] = greedPowerWords;
                    powerReport['lust'] = lustPowerWords;
                    powerReport['safety'] = safetyPowerWords;

                    let dialogueTagsSaidAsked = dialogueTags.filter(d => { if (d.word === "said" || d.word === "asked") return d; });

                    let wordFrequencyReport = _.map(_.groupBy(wordFrequency, "word"), (arr, word) => { return { name: word, weight: arr.length }; });

                    const makeScale = function (domain, range) {
                        var minDomain = domain[0];
                        var maxDomain = domain[1];
                        var rangeStart = range[0];
                        var rangeEnd = range[1];
                    
                        return (value) => {
                            return rangeStart + (rangeEnd - rangeStart) * ((value - minDomain) / (maxDomain - minDomain));
                        }
                    };

                    const minWeight = wordFrequencyReport.reduce((min, word) => (word.weight < min ? word.weight : min), wordFrequencyReport[0].weight);
                    const maxWeight = wordFrequencyReport.reduce((max, word) => (word.weight > max ? word.weight : max), wordFrequencyReport[0].weight);

                    const scale = makeScale([minWeight, maxWeight], [0.3, 1]);

                    let wordFrequencyData = wordFrequencyReport.map(word => ({ name: word.name, weight: word.weight, color: `rgba(7, 39, 73, ${scale(word.weight)})` }));

                    let readingTime = (quillContent.replace(new RegExp(' ', 'g'), '').length * 14.69).toFixed(0);

                    setReport({
                        lastReportIndexes: { start: 0, end: quill.getLength() },
                        data: {
                            cliches: cliches,
                            ingStarter: ingStarter,
                            readingTime: readingTime,
                            pronStarter: pronStarter,
                            nounStarter: nounStarter,
                            powerReport: powerReport,
                            dialogueTags: dialogueTags,
                            textReadability: textReadability,
                            sentenceStarter: sentenceStarter,
                            sentenceDialogue: sentenceDialogue,
                            conjunctionStarter: conjunctionStarter,
                            wordFrequencyReport: wordFrequencyData,
                            dialogueTagsSaidAsked: dialogueTagsSaidAsked,
                            sentenceVariationData: sentenceVariationDataReport,
                            paragraphVariationData: paragraphVariationDataReport,
                        },
                    });

                    setLoader(false);
                }
            }, 1000);
        } catch (error) {
            setLoader(false);
            setErrMsg([{ title: "Network Error!", description: "Something went wrong, Please try again later." }]);
        }
    }

    const exportToPDF = (e) => {
        e.preventDefault();
        setTimeout(async () => {
            handlePrint();
        }, 0);
    }

    const calculateTime = (time) => {
        var h = Math.floor(time / 3600);
        var m = Math.floor(time % 3600 / 60);
        var s = Math.floor(time % 3600 % 60);
        var hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hrs, ") : "";
        var mDisplay = m > 0 ? m + (m == 1 ? " min, " : " mins, ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
        return hDisplay + mDisplay + sDisplay;
    }

    let cliches = [];
    let ingStarter = [];
    let readingTime = [];
    let pronStarter = [];
    let nounStarter = [];
    let powerReport = [];
    let dialogueTags = [];
    let textReadability = [];
    let sentenceStarter = [];
    let sentenceDialogue = [];
    let conjunctionStarter = [];
    let sentenceVariationData = [];
    let paragraphVariationData = [];
    let dialogueTagsSaidAsked = [];
    let wordFrequencyReportData = [];

    if (report?.data) {
        cliches = report.data.cliches || [];
        ingStarter = report.data.ingStarter || [];
        readingTime = report.data.readingTime || '';
        pronStarter = report.data.pronStarter || [];
        nounStarter = report.data.nounStarter || [];
        powerReport = report.data.powerReport || [];
        dialogueTags = report.data.dialogueTags || [];
        textReadability = report.data.textReadability || [];
        sentenceStarter = report.data.sentenceStarter || [];
        sentenceDialogue = report.data.sentenceDialogue || [];
        conjunctionStarter = report.data.conjunctionStarter || [];
        sentenceVariationData = report.data.sentenceVariationData || [];
        dialogueTagsSaidAsked = report.data.dialogueTagsSaidAsked || [];
        paragraphVariationData = report.data.paragraphVariationData || [];
        wordFrequencyReportData = report.data.wordFrequencyReport || [];
    }

    return (
        <div>
            <div className={summaryReportPopup ? "modal fade show" : "modal fade"} id="summaryFullscreen">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title align-items-center w-100" id="summaryFullscreenLabel">
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-6 text-start">
                                            <p className="mb-0">Summary Report</p>
                                        </div>
                                        <div className="col-6 text-end">
                                            <button data-titlebottom="You can export as pdf using save button from the print popup." className="theme-btn6 fs-12px me-3 mb-2" onClick={(e) => exportToPDF(e)} >
                                                <span> Export as PDF</span>
                                                <span className="mdi mdi-file-export-outline ms-1"></span>
                                            </button>
                                            <button className="theme-btn2 fs-12px" onClick={toggleSummaryReport} >
                                                <span> Close</span>
                                                <span className="mdi mdi-close ms-1"></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </h5>
                        </div>
                        <div className="modal-body" ref={componentRef}>
                            {loader && 
                                <div>
                                    {/* <Card2 title={"Process Awaiting"} description={"We are trying to fetch the data, Please wait till we receive all your history."}></Card2> */}
                                    <div className="card__animate mb-2"></div>
                                    <div className="card__animate_mini mb-2"></div>
                                    <div className="card__animate_mini mb-2"></div>
                                    <div className="card__animate_mini mb-2"></div>
                                </div>
                            }
                            {!loader && errMsg.length > 0 && 
                                <Card2 title={errMsg[0].title} description={errMsg[0].description}></Card2>
                            }
                            <div className="report_page_1 px-3">
                            {textReadability.length > 0 && 
                                <>
                                    <div className="summary_report lh-lg row">
                                        <div className="col-md-12 mb-3 py-2 text-start">
                                            <p className="mb-0 fs-14px">This report provides an overview of readability, sentence and paragraph variation, and keywords usage within content. It also offers suggestions on how to improve these aspects of your writing. By following these suggestions, you can improve your writing skills and make your text more readable, interesting, and visible.</p>
                                        </div>
                                        <div className="col-lg-2 col-md-2 mb-3">
                                            <div className="card theme-btn10 p-0">
                                                <div className="card-body text-start">
                                                    <p className="mb-1 fs-5 lh-base"><span className="mdi mdi-text-search-variant me-2"></span>{textReadability[12]?.value ? textReadability[12]?.value : 0}</p>
                                                    <p className="fs-10px mb-0">Word Count</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-2 mb-3">
                                            <div className="card theme-btn10 p-0">
                                                <div className="card-body text-start">
                                                    <p className="mb-1 fs-5 lh-base"><span className="mdi mdi-text-search-variant me-2"></span>{textReadability[11]?.value ? textReadability[11]?.value : 0}</p>
                                                    <p className="fs-10px mb-0">Sentence Count</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-2 col-md-2 mb-3">
                                            <div className="card theme-btn10 p-0">
                                                <div className="card-body text-start">
                                                    <p className="mb-1 fs-5 lh-base"><span className="mdi mdi-text me-2"></span>{textReadability[13]?.value ? textReadability[13]?.value : 0}</p>
                                                    <p className="fs-10px mb-0">Avg. Sentence Length</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 mb-3">
                                            <div className="card theme-btn10 p-0">
                                                <div className="card-body text-start">
                                                    <p className="mb-1 fs-5 lh-base"><span className="mdi mdi-book-open-page-variant-outline me-2"></span>{readingTime.length > 0 ? calculateTime(Number(readingTime) / 1000) : '0 Secs'}</p>
                                                    <p className="fs-10px mb-0">Reading Time</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-md-3 mb-3">
                                            <div className="card theme-btn10 p-0">
                                                <div className="card-body text-start">
                                                    <p className="mb-1 fs-5 lh-base"><span className="mdi mdi-volume-high me-2"></span>{readingTime.length > 0 ? calculateTime(Number(readingTime) * 2 / 1000) : '0 Secs'}</p>
                                                    <p className="fs-10px mb-0">Speaking Time</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-7 text-start my-3">
                                            <p className="mb-3 fs-5 fw-medium">Text Readability</p>
                                            <p className="fs-14px">Text readability scores are tools that help writers to determine the level of difficulty of a given text. There are several different readability scores, each with its own strengths and weaknesses. The most commonly used readability scores are the <b className="fw-semibold">Dale-Chall readability score</b>, the <b className="fw-semibold">Flesch readability score</b>, the <b className="fw-semibold">Coleman-Liau readability score</b>, and the <b className="fw-semibold">SMOG Index</b>.</p>
                                            {/* <p className="fs-14px">Text readability scores can be useful for writers who want to <b className="fw-semibold">improve the readability</b> of their content. By knowing the level of difficulty of a given text, writers can make decisions about how to improve the readability of their content. For example, if a text has a high <b className="fw-semibold">Dale-Chall readability score</b>, it may be <b className="fw-semibold">difficult for some readers to understand</b>. In this case, the writer may want to consider breaking the text down into smaller, more manageable chunks.</p> */}
                                            <p className="fs-14px">Each of these readability scores uses a different formula to determine the level of difficulty of a text. <b className="fw-semibold">The Dale-Chall readability score</b>, for example, looks at the number of <b className="fw-semibold">difficult words</b> in a text. The <b className="fw-semibold">Flesch readability score</b> looks at the average number of words per sentence and the average number of syllables per word. The <b className="fw-semibold">Coleman-Liau readability score</b> looks at the average number of letters per word and the average number of words per sentence. The <b className="fw-semibold">SMOG Index</b> looks at the number of <b className="fw-semibold">polysyllabic words</b> in a text.</p>
                                        </div>
                                        <div className="col-md-5 my-auto">
                                            <div className="row">
                                                <div className="col-6 mb-3">
                                                    <div className="card theme-btn4 p-0">
                                                        <div className="card-body text-start">
                                                            <p className="mb-1"><span className="mdi mdi-text-box-check-outline me-2"></span>{textReadability[0]?.value ? textReadability[0]?.value : 'NA'}</p>
                                                            <p className="fs-10px mb-0">Text Standard</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <div className="card theme-btn4 p-0">
                                                        <div className="card-body text-start">
                                                            <p className="mb-1"><span className="mdi mdi-text-account me-2"></span>{textReadability[1]?.value ? textReadability[1]?.value : 'NA'}</p>
                                                            <p className="fs-10px mb-0">Grade Level</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <div className="card theme-btn4 p-0">
                                                        <div className="card-body text-start">
                                                            <p className="mb-1"><span className="mdi mdi-file-document-alert-outline me-2"></span>{textReadability[3]?.value ? textReadability[3]?.value : 0}</p>
                                                            <p className="fs-10px mb-0">Flesch Reading Score</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <div className="card theme-btn4 p-0">
                                                        <div className="card-body text-start">
                                                            <p className="mb-1"><span className="mdi mdi-file-document-alert-outline me-2"></span>{textReadability[6]?.value ? textReadability[6]?.value : 0}</p>
                                                            <p className="fs-10px mb-0">Dale-Chall Score</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <div className="card theme-btn4 p-0">
                                                        <div className="card-body text-start">
                                                            <p className="mb-1"><span className="mdi mdi-check-underline-circle-outline me-2"></span>{textReadability[4]?.value ? textReadability[4]?.value : 0}</p>
                                                            <p className="fs-10px mb-0">Gunning Fog Index</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-6 mb-3">
                                                    <div className="card theme-btn4 p-0">
                                                        <div className="card-body text-start">
                                                            <p className="mb-1"><span className="mdi mdi-check-underline-circle-outline me-2"></span>{textReadability[5]?.value ? textReadability[5]?.value : 0}</p>
                                                            <p className="fs-10px mb-0">Coleman Liau Index</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 mb-3 text-start">
                                            <p className="fs-14px">Text readability scores are just <b className="fw-semibold">one tool</b> that writers can use to <b className="fw-semibold">improve the readability</b> of their content. Other tools, such as the <b className="fw-semibold">use of keywords</b>, can also be helpful. Ultimately, it is up to the writer to decide what strategies to use in order to improve the readability of their content.</p>
                                        </div> */}
                                    </div>
                                    <hr />
                                    <div className="page-break"></div>
                                </>
                            }
                            {dialogueTags.length > 0 && dialogueTagsSaidAsked.length > 0 && 
                                <>
                                    <div className="dialogue_report lh-lg row">
                                        <div className="col-md-12 mb-3 py-2 text-start">
                                            <p className="mb-3 fw-medium">Dialogue Tags vs Said, Asked</p>
                                            <p className="fs-14px">When it comes to writing dialogue, there are a few different ways that you can go about it. You can use said, asked, or a variety of other dialogue tags. But which one should you use? And does it even matter?</p>
                                            <p className="fs-14px">Here's a look at the dialogue tags report and how you can use them:</p>
                                        </div>
                                        <div className="col-md-5 my-auto">
                                            {dialogueTags.length > 0 && dialogueTagsSaidAsked.length > 0 && 
                                                <DialogueTagsGraph 
                                                    dialogueReport={dialogueTags.length}
                                                    dialogueReportFiltered={dialogueTagsSaidAsked.length}
                                                />
                                            }
                                        </div>
                                        <div className="col-md-7 my-auto text-start">
                                            <p className="fs-14px"><b className="fw-semibold">Said</b><br />Said is probably the most commonly used dialogue tag. It's simple and to the point, and it doesn't carry a lot of extra baggage with it. Said can be used in a variety of situations and it won't necessarily change the tone of your dialogue.</p>
                                            <p className="fs-14px"><b className="fw-semibold">Asked</b><br />Asked is another common dialogue tag, and it can be used in many of the same situations as said. The main difference is that asked implies that the speaker is asking a question. This can be helpful in conveying the tone of the dialogue, but be careful not to overuse it or it will start to sound repetitive.</p>
                                            <p className="fs-14px"><b className="fw-semibold">Dialogue Tags</b><br />Dialogue tags are a great way to add variety to your dialogue and to convey the tone of the conversation. However, be careful not to overdo it. A few dialogue tags here and there are fine, but if every other word is a dialogue tag, it will start to sound choppy and unnatural.</p>
                                            <p className="fs-14px">So, which dialogue tag should you use? The answer is: it depends. Ultimately, it's up to you as the author to decide which dialogue tag best suits the situation. Just be sure to use them sparingly and to let the dialogue itself do most of the talking.</p>
                                            <p className="fs-14px">When you're writing a book, it's important to choose the right dialogue tags to convey the tone of voice you want. For example, if you want to sound convincing, you might want to use dialogue tags like "argued" or "insisted." On the other hand, if you want to sound more neutral, you might want to use tags like "said" or "asked."</p>
                                        </div>
                                    </div>
                                    <div className="page-break"></div>
                                </>
                            }
                            {sentenceVariationData.length > 0 && paragraphVariationData.length > 0 && 
                                <>
                                    <div className="variation_report lh-lg row">
                                        <div className="col-md-12 mb-3 py-2 text-start">
                                            <p className="mb-3 fs-5 fw-medium">Sentence & Paragraph Variation</p>
                                            <p className="fs-14px">It is often said that <b className="fw-semibold">varying sentence length</b> is one of the most <b className="fw-semibold">important aspects of writing</b>. This is because varying sentence length can help to set the tone of your writing, and can also be used to <b className="fw-semibold">emphasize certain points</b>. Convincing someone to do something can be difficult. You need to find the right balance of explanation and persuasion, without sounding pushy.</p>
                                            <article className="bg-light fs-12px p-3 fst-italic my-2"><b className="fw-semibold">For example,</b> shorter sentences can be used to convey a sense of urgency, or to make a point that is meant to be memorable. On the other hand, longer sentences can be used to provide background information, or to make a more complex argument.</article>
                                        </div>
                                        <div className="col-md-4 offset-md-2 my-auto">
                                            {sentenceVariationData.length > 0 && 
                                                <VariationGraph 
                                                    variationReport={sentenceVariationData}
                                                    title={'Sentencewise Count'}
                                                    color={'#dcaa2080'}
                                                    type={'sentences'}
                                                />
                                            }
                                        </div>
                                        <div className="col-md-4 my-auto">
                                            {paragraphVariationData.length > 0 && 
                                                <VariationGraph 
                                                    variationReport={paragraphVariationData}
                                                    title={'Paragraphwise Count'}
                                                    color={'#2d9cdb80'}
                                                    type={'paragraphs'}
                                                />
                                            }
                                        </div>
                                        {/* <div className="col-md-12 mb-3 py-2 text-start">
                                            <p className="fs-14px">In general, it is advisable to use a mix of both long and short sentences. This will ensure that your writing is both effective and easy to read. However, it is also important to be aware of the tone that you wish to set with your writing, as this will dictate the overall length of your sentences. </p>
                                        </div> */}
                                    </div>
                                    <hr />
                                    <div className="page-break"></div>
                                </>
                            }
                            {/* </div>
                            <div className="report_page_2 px-3"> */}
                            {(powerReport.anger?.length > 0 || powerReport.encourage?.length > 0 || powerReport.energetic?.length > 0 || powerReport.fear?.length > 0 || powerReport.forbidden?.length > 0 || powerReport.general?.length > 0 || powerReport.greed?.length > 0 || powerReport.lust?.length > 0 || powerReport.safety?.length > 0) && 
                                <>
                                    <div className="power_report lh-lg row">
                                        <div className="col-12 py-2 text-start">
                                            <p className="mb-3 fs-5 fw-medium">Dominant Words</p>
                                            <p className="fs-14px">If you want your writing to pack a punch, you need to use power words. These words convey strong emotions and can help you persuade, encourage, or motivate your readers.</p>
                                        </div>
                                        <div className="col-md-5 my-auto">
                                            {(powerReport.anger?.length > 0 || powerReport.encourage?.length > 0 || powerReport.energetic?.length > 0 || powerReport.fear?.length > 0 || powerReport.forbidden?.length > 0 || powerReport.general?.length > 0 || powerReport.greed?.length > 0 || powerReport.lust?.length > 0 || powerReport.safety?.length > 0) && 
                                                <PowerWordsGraph
                                                    graphData={powerReport}
                                                />
                                            }
                                        </div>
                                        <div className="col-md-7 py-2 text-start">
                                            <p className="fs-14px"><b className="fw-semibold">Anger words -</b> When you want to convey anger or frustration, consider using these power words: Frustrated, Mad, Angry, Furious, Irate, Exasperated etc.</p>
                                            <p className="fs-14px"><b className="fw-semibold">Encourage words -</b> If you want to encourage or motivate your readers, consider using these power words: Confident, Determined, Encouraged, Empowered, Motivated, Optimistic, Passionate, Strong etc.</p>
                                            <p className="fs-14px"><b className="fw-semibold">Lust words -</b> When you want to convey desire or lust, consider using these power words: Desire, Lust, Passion, Crave, Hunger, Longing, Thirst etc.</p>
                                            <p className="fs-14px"><b className="fw-semibold">Safety words -</b> If you want to make your readers feel safe, consider using these power words: Secure, Safe, Protected, Guarded, Shielded etc.</p>
                                            <p className="fs-14px"><b className="fw-semibold">Energetic words -</b> When you want to convey energy or excitement, consider using these power words: Energized, Enthusiastic, Excited, Exhilarated, Giddy, Invigorated etc.</p>
                                            <p className="fs-14px"><b className="fw-semibold">Fear words -</b> When you want to create a sense of fear or suspense, consider using these power words: Afraid, Anxious, Apprehensive, Fearful, Miserable, Nervous, Scared etc.</p>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="page-break"></div>
                                </>
                            }
                            {(powerReport.anger?.length > 0 || powerReport.encourage?.length > 0 || powerReport.energetic?.length > 0 || powerReport.fear?.length > 0 || powerReport.forbidden?.length > 0 || powerReport.general?.length > 0 || powerReport.greed?.length > 0 || powerReport.lust?.length > 0 || powerReport.safety?.length > 0) && 
                                <>
                                    <div className="openers_report lh-lg row">
                                        <div className="col-md-12 mb-3 py-2 text-start">
                                            <p className="mb-3 fs-5 fw-medium">Sentence Openers</p>
                                            <p className="fs-14px">When you're writing, it's important to use <b className="fw-semibold">sentence openers</b> that will <b className="fw-semibold">set the tone</b> for the rest of your piece. If you want to sound <b className="fw-semibold">convincing</b>, there are a few key words and phrases you can use. <b className="fw-semibold">Conjunctions</b> like "because" and "however" are good for linking two ideas together. <b className="fw-semibold">Pronouns</b> like "we" and "you" can create a sense of togetherness. And finally, using <b className="fw-semibold">active verbs</b> like "we're creating" or "you're experiencing" can help to <b className="fw-semibold">engage the reader</b>.</p>
                                            {/* <p className="fs-14px">If you're looking to add a <b className="fw-semibold">little variety to your writing</b>, using different types of sentence openers can be a great way to do so. Not only can it <b className="fw-semibold">make your writing more interesting to read</b>, but it can also help to <b className="fw-semibold">convey the desired tone and atmosphere</b>. Here are some different types of sentence openers and how to use them:</p> */}
                                        </div>
                                        <div className="col-md-6 mb-3 py-2 text-start">
                                            <p className="fs-14px"><b className="fw-semibold">Conjunction -</b> If you're looking to add a little variety to your writing, using different types of sentence openers can be a great way to do so. Not only can it make your writing more interesting to read, but it can also help to convey the desired tone and atmosphere.</p>
                                            <p className="fs-14px"><b className="fw-semibold">Pronoun -</b> You can add variety to your writing by using different types of sentence openers. Not only will this make your writing more interesting to read, but it can also help to convey the desired tone and atmosphere.</p>
                                            <p className="fs-14px"><b className="fw-semibold">Noun -</b> Adding variety to your writing can be achieved by using different types of sentence openers. Not only will this make your writing more interesting to read, but it can also help to convey the desired tone and atmosphere.</p>
                                            <p className="fs-14px"><b className="fw-semibold">Ing Word -</b> By using different types of sentence openers, you can add variety to your writing. Not only will this make your writing more interesting to read, but it can also help to convey the desired tone and atmosphere.</p>
                                        </div>
                                        <div className="col-md-6 my-auto">
                                            {(powerReport.anger?.length > 0 || powerReport.encourage?.length > 0 || powerReport.energetic?.length > 0 || powerReport.fear?.length > 0 || powerReport.forbidden?.length > 0 || powerReport.general?.length > 0 || powerReport.greed?.length > 0 || powerReport.lust?.length > 0 || powerReport.safety?.length > 0) && 
                                                <InitialsGraph
                                                    sentenseStarter={sentenceStarter}
                                                    initialNoun={nounStarter}
                                                    initialProNoun={pronStarter}
                                                    initialIng={ingStarter}
                                                    initialConjunction={conjunctionStarter}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="page-break"></div>
                                </>
                            }
                            {sentenceDialogue.length > 0 && 
                                <>
                                    <div className="narrative_report lh-lg row">
                                        <div className="col-md-12 py-2 text-start">
                                            <p className="mb-3 fs-5 fw-medium">Dialogues & Narrative</p>
                                            <p className="fs-14px">If you are Convincing with your writing, then <b className="fw-semibold">dialogue</b> can be a <b className="fw-semibold">great way to show your characters and their relationships</b> with each other. It can also be a <b className="fw-semibold">great way to advance the plot of your story</b>. However, <b className="fw-semibold">dialogue can be difficult</b> to get right, and if not done properly, it can come across as <b className="fw-semibold">forced or unnatural</b>. <b className="fw-semibold">Narrative</b>, on the other hand, can be a great way to <b className="fw-semibold">show the inner thoughts and emotions</b> of your characters. It can also be a <b className="fw-semibold">great way to provide background information</b> on the world your story takes place in.</p>
                                            <article className="bg-light fs-12px p-3 fst-italic my-2">There is no one perfect way to determine how much dialogue to include in your writing. Most popular novels contain between 25% and 35% dialogue.</article>
                                        </div>
                                        <div className="col-md-12 py-2 text-start">
                                            <SentenceDialogueGraph
                                                sentenceReport={sentenceDialogue}
                                            />
                                        </div>
                                        {/* <div className="col-md-12 py-2 text-start">
                                            <article className="bg-light fs-12px p-3 fst-italic my-2">There is no one perfect way to determine how much dialogue to include in your writing. Most popular novels contain between 25% and 35% dialogue.</article>
                                            <p className="fs-14px">There is no one perfect way to determine how much dialogue to include in your writing. Most popular novels contain between 25% and 35% dialogue.</p>
                                            <p className="fs-14px">There is no one perfect way to determine how much dialogue to include in your writing. <b className="fw-semibold">Between 25% and 35%</b> of popular novels contain <b className="fw-semibold">dialogue</b>, though the amount of interaction between characters varies from book to book. interaction between characters, most popular novels contain between 25% and 35% dialogue.</p>
                                            <p className="fs-10px">Note: Ultimately, it is up to the author to decide which approach to take. Each has its own advantages and disadvantages, and it is up to the author to decide what is best for their story.</p>
                                        </div> */}
                                    </div>
                                    <hr />
                                    <div className="page-break"></div>
                                </>
                            }
                            {wordFrequencyReportData.length > 0 &&
                                <>
                                    <div className="cliches_report lh-lg row">
                                        <div className="col-md-12 mb-3 py-2 text-start">
                                            <p className="mb-3 fs-5 fw-medium">Word Cloud</p>
                                        </div>
                                        <div className="col-md-12 mb-3 py-2 text-start">
                                            <WordCloudGraph wordFreqWords={wordFrequencyReportData} />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="page-break"></div>
                                </>
                            }
                            {/* </div>
                            <div className="report_page_3 px-3"> */}
                            {cliches.length > 0 && 
                                <>
                                    <div className="cliches_report lh-lg row">
                                        <div className="col-md-12 mb-3 py-2 text-start">
                                            <p className="mb-3 fs-5 fw-medium">Cliches</p>
                                            <p className="fs-14px">It is no secret that <b className="fw-semibold">many writers</b>, both <b className="fw-semibold">experienced and inexperienced</b>, <b className="fw-semibold">rely heavily on cliches</b> in their writing. While there is <b className="fw-semibold">nothing inherently wrong</b> with using a cliché here and there, <b className="fw-semibold">too much reliance</b> on them can make your writing seem <b className="fw-semibold">trite and unoriginal</b>. Furthermore, <b className="fw-semibold">overuse</b> of cliches can make your writing <b className="fw-semibold">difficult to read and understand</b>.</p>
                                        </div>
                                        <div className="col-md-6 mb-3 py-2 text-start">
                                            {/* <p className="fs-14px">So, how can you avoid using too many cliches in your writing? First, make a conscious effort to recognize them when you come across them. Second, take the time to brainstorm other, more original ways to express the same idea. And third, when in doubt, err on the side of caution and choose the more original option.</p> */}
                                            <p className="fs-14px">However, there are times when using a <b className="fw-semibold">cliché</b> can be <b className="fw-semibold">effective</b>, especially if you are trying to be <b className="fw-semibold">convincing</b>. Here are a few tips on how to use clichés without sounding like a broken record:</p>
                                            <ul>
                                                <li className="fs-14px">Use them sparingly. If you use too many clichés, your writing will start to sound like a bad country song. Use them sparingly, and only when they add something to your argument</li>
                                                <li className="fs-14px">Choose your clichés wisely. Some clichés are more overused than others. If you want to avoid sounding like everyone else, choose your clichés wisely</li>
                                                <li className="fs-14px">Put your own spin on them. If you must use a cliché, try to put your own spin on it. This will make it sound more original and less like something you just read in a book</li>
                                                <li className="fs-14px">Use them in moderation. As with anything else, using clichés in moderation is key. If you use them too much, they will lose their impact and you will start to sound like a broken record</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-5 my-auto mx-auto">
                                            <table className="table table-hover text-center fs-12px">
                                                <thead>
                                                    <tr>
                                                        <th className="fw-medium">#</th>
                                                        <th className="fw-medium">Maximum Used Cliches <small>(Top 10)</small></th>
                                                        <th className="fw-medium">Appearances</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cliches.map((cliche, index) => (
                                                        <tr key={index}>
                                                            <td>{Number(index) + 1}</td>
                                                            <td>{cliche.word}</td>
                                                            <td>{cliche.wordsCount}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}