import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { logout } from "../../services/authService";
import { getProfile } from "../../services/userService";

export default function SideNav ({ page, doc, recentDocuments, quill, setUpdateSettings, setPlanDocumentModal, exportFile, user, setShowOutlineModal, triggerFile, setModalToggle, manageSceneBeatOption, shortcuts, displayShortcuts }) {

    const [userData, setUserData] = useState(null);

    useEffect(() => {
        getProfile().then((result) => {
            setUserData(result.data.result)
        }).catch(error => console.log(error));
    }, [doc])

    const navigate = useNavigate();

    const addHeadings = () => {
        const range = quill.getSelection(true);
        let ops = [];
        if (range) {
            let placeholder = "Your header will come here\n";
            if (range.index > 0) ops.push({ "retain": range.index });
            ops.push({ "attributes": { "header": 1 }, "insert": placeholder });
            quill.updateContents(ops, 'user');
            quill.setSelection(range.index + placeholder.length - 1);
        }
    }

    const logoutUser = () => {
        logout();
        navigate("/login");
    };

    return (
        <div id="editor_sidebar" className="col-auto col-md-3 col-xl-2 px-0" style={{ background: '#f7f7f5', zIndex: 1 }}>
            <div className="d-flex flex-column align-items-center align-items-sm-start pt-2 text-white relative h-100" style={{ minHeight: 'calc(100vh - 80px)' }}>
                <ul className="nav nav-pills flex-column align-items-center align-items-sm-start position-sticky" style={{top:"70px",paddingBottom:"200px"}} id="menu">
                    {page == "dashboard" && 
                        <>
                            {userData && userData.packages[0].package_id != 19 && 
                                <li className="nav-item hover_effect" title-right="Dashboard">
                                    <a target="_blank" href="/" className="nav-link fw-normal align-middle px-0 text-dark">
                                        <i className="fs-14px mdi mdi-view-dashboard-outline"></i>
                                        <span className="ms-3 fw-medium fs-14px d-none d-sm-inline">Dashboard</span>
                                    </a>
                                </li>
                            }
                            {userData && userData.packages[0].package_id == 19 && 
                                <li className="nav-item hover_effect" title-right="Dashboard">
                                    <a target="_blank" href="/" className="nav-link fw-normal align-middle px-0 text-dark">
                                        <i className="fs-14px mdi mdi-creation"></i>
                                        <span className="ms-3 fw-medium fs-14px d-none d-sm-inline">Humanizer <span className="ms-2 badge theme-btn5 fs-8px align-middle rounded-1 fw-medium">New</span></span>
                                    </a>
                                </li>
                            }
                            <li className="nav-item hover_effect" title-right="Profile">
                                <a target="_blank" href="/profile" className="nav-link fw-normal align-middle px-0 text-dark">
                                    <i className="fs-14px color-theme mdi mdi-account"></i>
                                    <span className="ms-3 fw-medium fs-14px d-none d-sm-inline">Profile</span>
                                </a>
                            </li>
                            {userData && userData.packages[0].package_id != 19 && 
                                <li className="nav-item hover_effect" title-right="AI Templates">
                                    <a href={"/ai-editor"} className="nav-link fw-normal align-middle px-0">
                                        <i className="fs-14px color-theme mdi mdi-head-snowflake"></i>
                                        <span className="ms-3 fw-medium fs-14px d-none d-sm-inline text-dark">AI Templates <span className="ms-2 badge theme-btn5 fs-8px align-middle rounded-1 fw-medium">New</span></span>
                                    </a>
                                </li>
                            }
                            <li className="nav-item hover_effect" title-right="Pricing Plans">
                                <a target="_blank" href={"https://manuscripts.ai/pricing-plans/"} className="nav-link fw-normal align-middle px-0">
                                    <i className="fs-14px color-theme mdi mdi-cart-outline"></i>
                                    <span className="ms-3 fw-medium fs-14px d-none d-sm-inline text-dark">Pricing Plans</span>
                                </a>
                            </li>
                        </>
                    }
                    {page == "editor" && 
                        <>
                            <li onClick={(e) => setModalToggle(true)} className="nav-item hover_effect" title-right="New Document">
                                <button className="nav-link w-100 text-start fw-normal align-middle px-0">
                                    <i className="fs-14px mdi mdi-plus text-dark theme-btn5 rounded-circle text-white"></i>
                                    <span className="ms-3 fw-medium fs-14px d-none d-sm-inline text-dark">New Document</span>
                                </button>
                            </li>
                            <li className="nav-item hover_effect" title-right="Import File">
                                <button onClick={(e) => triggerFile(e)} className="nav-link w-100 text-start fw-normal align-middle px-0">
                                    <i className="fs-14px color-theme mdi mdi-file-upload-outline"></i>
                                    <span className="ms-3 fw-medium fs-14px d-none d-sm-inline text-dark">Import File</span>
                                </button>
                            </li>
                            <li className="nav-item bg-white pb-2">
                                <button className="nav-link w-100 text-start fw-normal align-middle fw-normal px-0">
                                    <span data-bs-toggle="collapse" className="text-dark fs-14px px-0 align-middle">
                                        <i className="mdi mdi-text-box-outline text-dark"></i>
                                        <span className="ms-3 fw-medium d-none d-sm-inline">Recent Documents</span>
                                    </span>
                                </button>
                                {recentDocuments && (
                                    <ul className="collapse show nav flex-column mt-2">
                                        {recentDocuments.map((document, key) => (
                                            <li title-right={document.title} className={`doc_link rounded fs-14px ${doc?.uuid == document.uuid ? 'active' : ''}`} key={key}>
                                                <a href={`/editor?doc=${document.uuid}`} className="text-decoration-none fw-normal px-0">{key + 1}. <span className="d-none d-sm-inline">{_.truncate(document.title, { length: 20 })}</span></a>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                            <li className="nav-item hover_effect" title-right="Add Heading">
                                <button onClick={(e) => addHeadings()} className="nav-link w-100 text-start fw-normal align-middle px-0">
                                    <i className="fs-14px color-theme mdi mdi-format-header-increase"></i>
                                    <span className="ms-3 fw-medium fs-14px d-none d-sm-inline text-dark">Add Heading</span>
                                </button>
                            </li>
                            {user?.user?.user_mode == "Author" &&
                                <>
                                    <li className="nav-item hover_effect" title-right="Lore Book">
                                        <button onClick={(e) => setPlanDocumentModal(true)} className="nav-link w-100 text-start fw-normal align-middle px-0">
                                            <i className="fs-14px color-theme mdi mdi-creation"></i>
                                            <span className="ms-3 fw-medium fs-14px d-none d-sm-inline text-dark">Lore Book <span className="ms-2 badge theme-btn5 fs-8px align-middle rounded-1 fw-medium">New</span></span>
                                        </button>
                                    </li>
                                    <li className="nav-item hover_effect" title-right="Add Chapter">
                                        <button onClick={(e) => manageSceneBeatOption(true)} className="nav-link w-100 text-start fw-normal align-middle px-0">
                                            <i className="fs-14px color-theme mdi mdi-format-list-bulleted"></i>
                                            <span className="ms-3 fw-medium fs-14px d-none d-sm-inline text-dark">Add Chapter</span>
                                        </button>
                                    </li>
                                </>
                            }
                            {user?.user?.user_mode == "Student" &&
                                <li className="nav-item hover_effect" title-right="Manage Outline">
                                    <button onClick={(e) => setShowOutlineModal(true)} className="nav-link w-100 text-start fw-normal align-middle px-0">
                                        <i className="fs-14px color-theme mdi mdi-format-list-numbered-rtl"></i>
                                        <span className="ms-3 fw-medium fs-14px d-none d-sm-inline text-dark">Manage Outline</span>
                                    </button>
                                </li>
                            }
                            <li className="nav-item hover_effect" title-right="AI Templates">
                                <a href={"/ai-editor"} className="nav-link fw-normal align-middle px-0">
                                    <i className="fs-14px color-theme mdi mdi-head-snowflake"></i>
                                    <span className="ms-3 fw-medium fs-14px d-none d-sm-inline text-dark">AI Templates <span className="ms-2 badge theme-btn5 fs-8px align-middle rounded-1 fw-medium">New</span></span>
                                </a>
                            </li>
                        </>
                    }
                </ul>
                <hr />
                <div className="dropdown w-100 px-3">
                    {page == "editor" && 
                        <>
                            <button onClick={(e) => exportFile(e)} className="align-items-center border-0 d-flex fw-normal p-1 text-start w-100"  title-right="Export Docx">
                                <i className="fs-16px color-theme mdi mdi-download-box-outline"></i>
                                <span className="d-none fw-medium d-sm-inline fs-14px ms-3">Export Docx</span>
                            </button>
                            <button onClick={(e) => setUpdateSettings(true)} className="align-items-center border-0 d-flex fw-normal p-1 text-start w-100 mt-2"  title-right="AI Settings">
                                <i className="fs-16px color-theme mdi mdi-cog"></i>
                                <span className="d-none fw-medium d-sm-inline fs-14px ms-3">AI Settings</span>
                            </button>
                            <button onClick={(e) => displayShortcuts(true)} className="align-items-center border-0 d-flex fw-normal p-1 text-start w-100 mt-2 text-muted"  title-right="Shortcuts">
                                <i className="fs-16px color-theme mdi mdi-share-all-outline"></i>
                                <span className="text-dark fw-medium d-none d-sm-inline fs-14px ms-3">Shortcuts</span>
                            </button>
                        </>
                    }
                    {page == "dashboard" && 
                        <>
                            <a title-right="Terms & Conditions" target="_blank" href="https://manuscripts.ai/terms-and-conditions/" className="align-items-center border-0 d-flex fw-normal p-1 text-start w-100 mt-2 text-decoration-none text-dark">
                                <span className="d-none fw-medium d-sm-inline fs-14px ms-3">Terms & Conditions</span>
                            </a>
                            <a title-right="Privacy Policy" target="_blank" href="https://manuscripts.ai/privacy-policy/" className="align-items-center border-0 d-flex fw-normal p-1 text-start w-100 mt-2 text-decoration-none text-dark">
                                <span className="d-none fw-medium d-sm-inline fs-14px ms-3">Policy</span>
                            </a>
                            <a title-right="GDPRCCPA" target="_blank" href="https://manuscripts.ai/gdprccpa/" className="align-items-center border-0 d-flex fw-normal p-1 text-start w-100 mt-2 text-decoration-none text-dark">
                                <span className="d-none fw-medium d-sm-inline fs-14px ms-3">GDPRCCPA</span>
                            </a>
                        </>
                    }
                    <button onClick={(e) => logoutUser()} className="align-items-center border-0 d-flex fw-normal p-1 text-start w-100 mt-2 text-muted"  title-right="Logout">
                        <i className="fs-16px color-theme mdi mdi-logout"></i>
                        <span className="text-dark d-none fw-medium d-sm-inline fs-14px ms-3">Logout</span>
                    </button>
                </div>
            </div>
        </div>
    )
}