import React, { useEffect, useState } from "react";
import { useOutletContext, Link } from "react-router-dom";
import _ from "lodash";
import { generateNotification } from "../../services/notificationService";
import { getSentenceVariation, getTextReadability, getWordsReportData } from "../../services/reportServices";
import { sanitizeResetQuillAndGetContent, highlightTextBlast, highlightTextBlastCustomTextReadabilitys, loadWordNet } from "../../services/highlightContent";
import Card2 from "../common/Cards/Card2";

export default function TextReadability() {

    const [
        docId,
        report,
        setReport,
        quill,
        setQuill,
        userData,
        setUserData,
        propsRouter,
        setAvailableTokens,
        loader,
        handleLoaderOff,
        checkPage,
        setCheckPage, 
        setAiResultOutput, 
        setShowAIResultPopup,
        handleBackActivity
    ] = useOutletContext();

    const [splashLoader, setSplashLoader] = useState(true);

    useEffect(() => {
        setReport({});
        console.log('text readability')
        setCheckPage('text readability');
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        if (quill && quill.getLength() > 3) {
            loadTextReadability();
        }
    }, [propsRouter]);

    const loadTextReadability = async () => {
        setSplashLoader(true);
        setTimeout(async () => {
            try {
               
                if (userData.packages[0] && userData.user) {
                    const quillContent = await sanitizeResetQuillAndGetContent(quill);
                    const userPackage = userData.packages[0];

                    let sentenceVariation = await getSentenceVariation(quillContent);
                    let response = await getTextReadability(quillContent, userData, sentenceVariation.result.length, sentenceVariation.totalWordCount);

                    let tRD = [];
                    tRD["readability"] = response[0].data.response;
                    tRD["dalechall"] = response[1].data.data;

                    setReport({
                        lastReportIndexes: { start: 0, end: quill.getLength() },
                        data: {
                            readability: tRD["readability"],
                            dalechall: _.map(_.groupBy(tRD["dalechall"], "word"), (adverbArr, word) => {
                                return { word: word, wordsCount: adverbArr.length, dataArr: adverbArr };
                            }),
                        },
                    });
                    setSplashLoader(false);
                } else {
                    setSplashLoader(false);
                    generateNotification({
                        title: "Account Expired!",
                        text: "Please check your account for the payment, This report is currently not available.",
                        icon: "error",
                    });
                }
            } catch (error) {
                setSplashLoader(false);
                generateNotification({
                    title: "Network Error!",
                    text: "Something went wrong, Please try again later!",
                    icon: "error",
                });
            }
        });
    }

    let readability = [], dalechall = [];
    if (report?.data) {
        readability = report.data.readability || [];
        dalechall = report.data.dalechall || [];
    }
    return (
        <div className="result_cover">
            <div className="top_nav">
                <div className="d-flex flex-lg-nowrap flex-md-wrap gap-2 justify-content-start align-items-center mb-2">
                    <Link data-title="Back To Editor" onClick={(e) => handleBackActivity(e)}><span className="mdi mdi-chevron-left-circle-outline me-2 theme-link fs-14px"></span></Link>
                    <span className="fw-medium fs-14px">Text Readability</span>
                    <a className="text-dark fs-10px fw-medium" target="_blank" href="https://manuscripts.ai/how-to/text-readability-report/"><span className="mdi mdi-alert-circle-outline ms-2"></span> HOW TO?</a>
                    <span className="ms-auto">
                        <button onClick={loadTextReadability} className="btn theme-btn4 fs-10px">Refresh</button>
                    </span>
                </div>
            </div>
            <div className="result_card_cover text-start">
                <div className="reportContainer me-2 mt-0">
                    {!splashLoader && readability.length == 0 && 
                        <Card2 title="Report Warning" description="Unable to fetch content or Provide out for this content, Please Refresh the report."></Card2>
                    }
                    {splashLoader && readability.length == 0 && 
                        <>
                            <Card2 title="Report Processing" description="We are trying to process report on your content, Please wait while we generate complete report."></Card2>
                            <div className="card__animate mb-2"></div>
                            <div className="card__animate_mini mb-2"></div>
                            <div className="card__animate_mini"></div>
                        </>
                    }
                    {readability && readability.map((v, i) => (
                        <div className={`card fs-12px text-start card-adv custom-card1 mb-3 p-0 reportcard-1`} key={i} >
                            <div className="card-body">
                                <div className="d-flex align-items-center">
                                    <div>{v.type}</div>
                                    <div className="ms-auto border p-2 rounded">
                                        <span className="count">{v.value}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}