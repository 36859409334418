import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { SSE } from "sse.js";
import Groq from "groq-sdk";
import NLPService from "../../services/nlpService";
import { getData } from "../../services/dataService";
import { checkModelTokenAvailability, checkReportPath } from "../../services/openAIService";
import { highlightTextParaphrasing, quillContentInsertBlotByContentIndex, sanitizeResetQuillAndGetContent, sanitizeResetQuillAndGetContentByRange, sanitizeTextGetContent } from "../../services/highlightContent";
import { checkDifference, getAddedTokens, getReportUsage, getTokenCount, getWordnetData, updateTokenUsages } from "../../services/reportServices";

import Button1 from "./Buttons/Button1";
import { converter, markdown_converter } from "../../services/deltaToMarkdown";

const groq = new Groq({ apiKey: process.env.REACT_APP_GROQ_API_KEY, dangerouslyAllowBrowser: true  });

export default function AIResultPopup ({ userData, inputQuill, start, end, doc, aiReportSettings, setAvailableTokens, showAIResultPopup, setShowAIResultPopup, aiResultOutput, setAiResultOutput, selectedLang, setSelectedLang, noWords, setNoWords, errMsg, setErrMsg, bookDetailsModel, bookDetailsCreativity, setTokenPurchaseErr, setCheckPage }) {

    const [loader, setLoader] = useState(false);
    const [sentenceLoader, setSentenceLoader] = useState(false);
    const [highlightIndex, setHighlightIndex] = useState([]);
    const [selectionStart, setSelectionStart] = useState(0);
    const [selectionLength, setSelectionLength] = useState(0);
    const [reportOption, setReportOption] = useState('');
    const [reportPrompt, setReportPrompt] = useState('');
    const [helpURI, setHelpURI] = useState('');
    const [resultOutput, setResultOutput] = useState('');
    const [modelName, setModelName] = useState(bookDetailsModel);
    const [styleAttr, setStyleAttr] = useState({})
    const [showDeleted, setShowDeleted] = useState(false);
    const [toggleRecommendation, setToggleRecommendation] = useState('word');
    const [listWordRecommendation, setListWordRecommendation] = useState({});
    const [listPhraseRecommendation, setListPhraseRecommendation] = useState([]);
    const [highlightContentLoader, setHighlightContentLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        setCheckPage('')
        setHighlightIndex({ 'start': start, 'length': end });
        setStyleAttr({ display: 'block', minWidth: '300px', right: '0', position: 'fixed', zIndex: '999' });
        setErrMsg({});
        setAiResultOutput([]);
        setListWordRecommendation({})
        setListPhraseRecommendation([])

        const card = document.querySelector('#word_card')
        if (card) card.style.display = 'none'


        sanitizeAndBlotInputQuillData();

        if (userData.user.user_mode == 'Student') {
            setModelName('openai/gpt-4-turbo');
        } else {
            setModelName(bookDetailsModel);
        }

        switch (aiReportSettings.report) {
            case 'Paraphrase':
                setReportOption(aiReportSettings.report_sub);
                if (userData.user.user_mode == 'Student') {
                    setHelpURI('https://manuscripts.ai/paraphrasing-using-selection-tools-students/')
                } else {
                    setHelpURI('https://manuscripts.ai/paraphrasing-selection-tools')
                }
                break;
            case 'AI Edit':
                setReportOption(aiReportSettings.report_sub);
                if (userData.user.user_mode == 'Student') {
                    setHelpURI('https://manuscripts.ai/ai-edit-selection-tools-students/')
                } else {
                    setHelpURI('https://manuscripts.ai/ai-edit-selection-tools')
                }
                break;
            case 'Perspective':
                setReportOption(aiReportSettings.report_sub);
                setHelpURI('https://manuscripts.ai/first-person-perspective-selection-tools')
                break;
            case 'AI Rewrite':
                setReportOption(aiReportSettings.report_sub);
                if (userData.user.user_mode == 'Student') {
                    setHelpURI('https://manuscripts.ai/rewrite-story-selection-tools-students/')
                } else {
                    setHelpURI('https://manuscripts.ai/rewrite-story-selection-tools')
                }
                break;
            case 'Expand Content with AI':
                setReportOption(aiReportSettings.report_sub);
                if (userData.user.user_mode == 'Student') {
                    setHelpURI('https://manuscripts.ai/expand-content-selection-tools-students/')
                } else {
                    setHelpURI('https://manuscripts.ai/expand-story-selection-tools')
                }
                break;
            case 'Complete Story with AI':
                setReportOption(aiReportSettings.report_sub);
                setHelpURI('https://manuscripts.ai/complete-story-selection-tools')
                break;
            case 'Opposing Arguments':
                setReportOption(aiReportSettings.report_sub);
                setHelpURI('https://manuscripts.ai/opposing-arguments-selection-tools/')
                break;
            case 'Content Improver':
                setReportOption(aiReportSettings.report_sub);
                if (userData.user.user_mode == 'Student') {
                    setHelpURI('https://manuscripts.ai/content-improver-selection-tools-students/')
                } else {
                    setHelpURI('https://manuscripts.ai/content-improver-selection-tools')
                }
                break;
            case 'Summary Generator':
                setReportOption(aiReportSettings.report_sub);
                if (userData.user.user_mode == 'Student') {
                    setHelpURI('https://manuscripts.ai/summary-generator-selection-tools-students/')
                } else {
                    setHelpURI('https://manuscripts.ai/summary-generator-selection-tools')
                }
                break;
            case 'Voice Update':
                setReportOption(aiReportSettings.report_sub);
                setHelpURI('https://manuscripts.ai/paraphrasing-selection-tools')
                break;
            case 'Improve Structure':
                setReportOption(aiReportSettings.report_sub);
                if (userData.user.user_mode == 'Student') {
                    setHelpURI('https://manuscripts.ai/improve-structure-using-selection-tools-students/')
                } else {
                    setHelpURI('https://manuscripts.ai/improve-structure-selection-tools')
                }
                break;
            case 'Translate':
                setReportOption(aiReportSettings.report_sub);
                setHelpURI('https://manuscripts.ai/translate-selection-tools-students/')
                break;
            default:
                break;
        }

        if (start >= 0 && end > 0) {
            setSelectionStart(start);
            setSelectionLength(end);

            loadAIReport(start, end, reportOption);
        } else {
            setErrMsg({ type: "warning", title: "Missing selection!", message: "You have not provided any selection for this report, You need to select the content using mouse selection or keyboard selection to proceed with the report!" });
            setSelectionStart(0);
            setSelectionLength(0);
        }

    }, [aiReportSettings, bookDetailsModel])

    const editorEventListnerAdd = (e) => {
        setListPhraseRecommendation([]);
        setListWordRecommendation({});
        setToggleRecommendation("");

        const card = document.querySelector('#word_card');

        if (document.getElementById("showDeleteCheck") && !document.getElementById("showDeleteCheck").checked && e.target.className !== "word-removed") {
            const bounds = e.target.getBoundingClientRect();
            const word = e.target.innerText;
            const sentence = e.target.parentElement.innerText;

            getWordnetData(word.replace(/[^a-zA-Z ]/g, "")).then((thesaurus) => {
                const recommendations = thesaurus.data.results || [];
                setListWordRecommendation({ word: word.replace(/[^a-zA-Z ]/g, ""), phrase: word, id: e.target.id, recommendations: recommendations });
            })

            let sentenceOptions = [];

            for (let index = 0; index < 3; index++) {
                setSentenceLoader(true);
                getGroqChatCompletion(sentence).then((result) => {
                    if (result.choices.length > 0) {
                        checkDifference(sentence, result.choices[0].message.content).then((diff) => {
                            diff = diff.data.data;
                            let html = "";
                            diff.map((d) => {
                                if (d.s == 'added')
                                    html += "<span class='word-added'>"+d.str+"</span>"
                                if (d.s == 'skipped')
                                    html += "<span >"+d.str+"</span>"
                            })
                            sentenceOptions.push({ html: html, sentence: sentence, phrase: sentence, output: result.choices[0].message.content, id: e.target.parentElement.id })
                            setListPhraseRecommendation(sentenceOptions);
                        }).catch(error => console.log(error))
                    }
                }).catch((error) => console.log(error))
            }
            setSentenceLoader(false);

            card.style.display = 'block';
            card.style.top = bounds.top - 250 - bounds.height + 'px';
        }
    }

    const sanitizeAndBlotInputQuillData = async () => {
        await sanitizeResetQuillAndGetContent(inputQuill);
        const input = await inputQuill.getText(start, end);
        let highlightData = [{ start: start, end: start+end, input: input }];
        let udate = await quillContentInsertBlotByContentIndex(inputQuill, 0, highlightData, "start", "end", [{
            blotName: "span",
            id: `paraphrase-${start}-${end}`,
            classes: [`info-bar`, `paraphrase-{input}`],
            classDynamicKeys: [ "", { replaceBy: "{input}", replaceToKey: "input" }],
        }]);
    }

    const handleHighlights = (newText) => {
        let quillContent = '';
        if (highlightIndex.length == 0) {
            quillContent = inputQuill.getText(start, end);
        } else {
            quillContent = inputQuill.getText(highlightIndex.start, highlightIndex.length);
        }

        checkDifference(quillContent, newText)
        .then((diff) => {
            let initial = 0;
            let content = '';

            const showDeleted = document.getElementById("showDeleteCheck") ? document.getElementById("showDeleteCheck").checked : true;

            diff.data.data.map((d) => {
                if (d.s != 'removed' || showDeleted) {
                    if (initial == 0) {
                        content += d.str.trim();
                    } else {
                        content += d.str;
                    }
    
                    initial += 1;
                }
            })

            
            const nlpService = new NLPService("");
            nlpService.setContent(content);
            let sentences = nlpService.getSentaces();

            let index = 0;
            let sentenceIndex = 0;
            let sentenceStart = sentences[sentenceIndex].stringEndIndex;

            let blotHtml = `<customtag id="sentence-${sentenceStart}" class="sentence">`;

            diff.data.data.map((d, i) => {
                let chunk = d.str;
                if (index == 0) chunk = chunk.trim()

                let arr = chunk.split(' ');

                let html = '';

                if (d.s !== 'removed' || showDeleted) {
                    arr.map((str) => {
                        if (str.length > 0) {
                            let srtArr = str.split("\n")
                            if (srtArr.length > 1) {
                                srtArr.map((words) => {
                                    if (words.length > 0) {
                                        index += words.length;
                                        html += `<span id="word-${index}" class="word-${d.s}">${words}</span>`;
                                    } else {
                                        index += 1;
                                        sentenceIndex += 1;
                                        if (sentenceIndex < sentences.length) {
                                            sentenceStart = sentences[sentenceIndex].sentenceStartIndex + sentences[sentenceIndex].stringEndIndex;
                                        }
                                        html += `<span class="word-${d.s}"> </span>`;
                                        html += `</customtag><p></br></p><customtag id="sentence-${sentenceStart}" class="sentence">`;
                                    }
                                })
                            } else {
                                if (index > sentenceStart) {
                                    sentenceIndex += 1;
                                    if (sentenceIndex < sentences.length) {
                                        sentenceStart = sentences[sentenceIndex].sentenceStartIndex + sentences[sentenceIndex].stringEndIndex;
                                    }
                                    html += `</customtag><customtag id="sentence-${sentenceStart}" class="sentence">`;
                                }
                                index += str.length;
                                html += `<span id="word-${index}" class="word-${d.s}">${str}</span>`;
                            }
                        } else {
                            index += 1;
                            html += `<span class="word-${d.s}"> </span>`;
                        }
                    })
                }

                blotHtml += html;
            })

            blotHtml += `</customtag>`;

            document.querySelector("#dummy-editor").innerHTML = blotHtml;

            let added = document.querySelectorAll('span.word-added');

            for (let index = 0; index < added.length; index++)
                added[index].removeEventListener("click", editorEventListnerAdd);
 
            for (let index = 0; index < added.length; index++)
                added[index].addEventListener("click", editorEventListnerAdd);
 
            let skipped = document.querySelectorAll('span.word-skipped');

            for (let index = 0; index < skipped.length; index++)
                skipped[index].removeEventListener("click", editorEventListnerAdd);
 
            for (let index = 0; index < skipped.length; index++)
                skipped[index].addEventListener("click", editorEventListnerAdd);

        }).catch((error) => {
            console.log("Unable to load difference api!")
        })

        setHighlightContentLoader(false);
    }

    const triggerAIReportAPI = async (
        sentences = [],
        start,
        length,
        type,
        quillContent,
        usageLimit,
        totalLimit, 
        modelLimit
    ) => {
        try {
            let editor = document.getElementById("dummy-editor");
            const length = editor.innerText.length;

            editor.innerText = '';

            if (!sentences.length) {
                setLoader(false);
                setErrMsg({ type: "warning", title: "Missing selection!", message: "You have not provided any selection for this report, Please select content and refresh the report!" });
                return;
            }

            let paraphraseOutput = [];
            let paraphraseData = [];
            let nth = 0;

            const uriPath = await checkReportPath(aiReportSettings.report);
            const urlWithData = new URL(uriPath);
            let input_data = {
                AuthToken: `${getData("token_type")} ${getData("token")}`,
                type: aiReportSettings.report_sub,
                n: 1,
                temperature: bookDetailsCreativity,
                user_mode: userData.user.user_mode,
                maxToken: modelLimit,
                genre_type: doc.genre_type,
                genre: userData.user.genre,
                prompt: reportPrompt,
                content: quillContent,
                model_name: modelName,
                language: selectedLang
            }
  
            const source = new SSE(urlWithData, {
                headers: {
                    "Content-Type": "application/json"
                },
                payload: JSON.stringify(input_data),
            });

            source.onreadystatechange = async function(event) {
                if (event.readyState === source.OPEN) {
                    setLoader(true);
                }
  
                if (event.readyState === source.CLOSED) {
                    setLoader(false);
                    source.close();
                    await setResultOutput(paraphraseOutput[nth]);

                    paraphraseData[nth] = {
                        start: start,
                        end: start + length,
                        input: quillContent,
                        type: aiReportSettings.report_sub,
                        output: paraphraseOutput[nth],
                    };

                    let all_output = '', total_used_limit = 0;
                    await setHighlightContentLoader(true);

                    setTimeout(() => {
                        handleHighlights(paraphraseOutput[nth]);
                    }, 2000);

                    for (const data of paraphraseData) {
                        if (data.output && data.output != undefined) {
                            all_output += data.output + "";
                            let charCount = await updateTokenUsages("paraphrasing-report-v1", doc.id, "Paraphrasing " + type + ": " + data.input, data.output);
                            charCount = charCount ? charCount : 0;
                            usageLimit = totalLimit - (Number(charCount) + Number(usageLimit)) > 0 ? totalLimit - (Number(charCount) + Number(usageLimit)) : 0;
                            total_used_limit += charCount;
                            setAvailableTokens(usageLimit);
                        }
                    }
  
                    if (all_output.length < 3) {
                        setErrMsg({
                            type: "warning",
                            title: "Content Processed",
                            message: "Could not generate content for the given selection or Looks like it is already valid content, Please try with some different Content."
                        });
                    }
                }
            };
  
            source.onmessage = function(event) {
                const data = JSON.parse(event.data);
                if (data?.content) {
                    nth = data?.nth;
                    paraphraseOutput[nth] = (paraphraseOutput[nth] || "") + data?.content;
  
                    paraphraseData[nth] = {
                        start: start,
                        end: start + length,
                        input: quillContent,
                        type: type,
                        output: paraphraseOutput[nth],
                    };

                    setAiResultOutput(paraphraseOutput);

                    editor.innerText = paraphraseOutput[nth]

                    if (data?.close == 'True') {
                        source.close();
                    }
                }
            };
  
            source.onerror = function(event) {
                source.close();
                setErrMsg({
                    type: "warning",
                    title: "Network Error!",
                    message: "Something went wrong, Please check the details provided for the report query are valid and try again."
                });
                setLoader(false);
            };

        } catch (error) {
            setLoader(false);
            setErrMsg({ type: "warning", title: "Network Error!", message: "Something went wrong, This could be due to  API downtime. Please try again later." });
        }
    }

    const loadAIReport = (start, length, type) => {
        setErrMsg({});
        setTokenPurchaseErr({})
        setLoader(true);

        setTimeout(async () => {
            try {
                let usage = await getReportUsage();

                if (usage[0].data.result.statusCode == 200) {
                    var usageLimit = usage[0].data.result.result;
                    if (userData.packages[0] && userData.user) {
                        if (userData.packages[0].amount <= 100) usageLimit = usage[0].data.result.usage_count_day;
                        let quillContent = await sanitizeResetQuillAndGetContentByRange(inputQuill, start, length);
                        const quillDelta = inputQuill.getContents(start, length);
                        quillContent = await converter(quillDelta);

                        if (quillContent.length > 4) {
                            let input_token_data = await getTokenCount(quillContent);
                            let input_token_count = input_token_data.data.result.length;

                            let modelLimit = await checkModelTokenAvailability(modelName);

                            if (input_token_count > modelLimit) {
                                setLoader(false);
                                setErrMsg({ type: "warning", title: "Model Limit Exceed!", message: "The model you have selected has limitations of "+Number(modelLimit * 0.75)+" words, Please select models with the greater capacity or reduce your selection." });
                                return;
                            }

                            if (input_token_count > 8000) {
                                setLoader(false);
                                setErrMsg({ type: "warning", title: "Limit exceed!", message: "Character limit exceeded. You can not select more than 8000 characters." });

                                return;
                            }

                            let nlpService = new NLPService("");
                            nlpService.setContent(quillContent);
                            let data = nlpService.getSentaces();

                            let addOnTokens = await getAddedTokens(userData.user.id);
                            addOnTokens = addOnTokens.data.result.tokens[0].tokens ? Number(addOnTokens.data.result.tokens[0].tokens) : 0;
                            let totalLimit = parseInt(userData.packages[0].amount) <= 100 ? 1000 + addOnTokens : 200000 + addOnTokens;

                            let today = moment().format("YYYY-MM-DD H:mm:ss");
                            const date_limit = moment(userData.packages[0].package_start).add(7, 'days').format("YYYY-MM-DD H:mm:ss");

                            if (date_limit < today && parseInt(userData.packages[0].amount) <= 100) {
                                setLoader(false);
                                setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your trial plan has been expired and not renewed yet, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                            } else if (parseInt(usageLimit) >= (1000 + addOnTokens) && parseInt(userData.packages[0].amount) <= 100) {
                                setLoader(false);
                                setTokenPurchaseErr({ type: "warning", title: "Subscription Limit Error: ", message: "Your account has exceeded trial plan daily limit of 1000 token, you can visit again once your subscription is renewed or you can also purchase additional tokens!" });
                            } else if (usageLimit < totalLimit) {
                                if (Number(input_token_count) + Number(usageLimit) + (userData.user.size ? userData.user.size : 100) < totalLimit) {
                                    await triggerAIReportAPI(data, start, length, type, quillContent, usageLimit, totalLimit, modelLimit);
                                } else {
                                    setLoader(false);
                                    setTokenPurchaseErr({ type: "warning", title: "Limit exceed!", message: "Your AI Report Usage limit is exceeding, You can make selection of smaller content or upgrade your plan." });
                                }
                            } else {
                                setLoader(false);
                                setTokenPurchaseErr({ type: "warning", title: "Limit exceed!", message: "Your AI Report Usage limit has been expired, You can purchase Additional Tokens Plan for additional limit." });
                            }

                        } else {
                            setLoader(false);
                            setErrMsg({ type: "warning", title: "Missing selection!", message: "You have not provided any selection for this report, Please select content and refresh the report!" });
                        }
                    } else {
                        setLoader(false);
                        setErrMsg({ type: "warning", title: "Permission Denied!", message: "Paraphrasing service is not available for this account." });
                    }

                } else {
                    setLoader(false);
                    setErrMsg({ type: "warning", title: "Permission Denied!", message: "Paraphrasing service is not available for this account." });
                }
            } catch (error) {
                setLoader(false);
                setErrMsg({ type: "warning", title: "Network Error!", message: "Something went wrong, Please try again later." });
            }
        }, 0);
    }

    const getGroqChatCompletion = async (sentence) => {
        return groq.chat.completions.create({
            messages: [{
                role: "system",
                content: "Paraphrase the given content. \nDo not add any additional comments.",
            }, {
                role: "user",
                content: sentence,
            }],
            model: "llama3-8b-8192",
        });
    }

    const showDeleteChange = (newText, status) => {
        setShowDeleted(status);
        setHighlightContentLoader(true);
        setListPhraseRecommendation([]);
        setListWordRecommendation({});
        setToggleRecommendation("");
        handleHighlights(newText);
    }

    const checkData = async (e) => {
        e.preventDefault();
        setErrMsg({});
        await loadAIReport(start, end, reportOption);
    }

    let handleTextParaphrasing = async (resultOutput, className) => {
        // await highlightTextParaphrasing(quill, resultOutput, highlightIndex, setHighlightIndex, className, selectionStart, selectionLength);
        let ops = await markdown_converter(resultOutput);
        let updateOps = [{ retain: highlightIndex.start }, { delete: highlightIndex.length }]

        let startIndex = highlightIndex.start;

        ops.map((op) => {
            if (op?.attributes) {
                updateOps.push({ insert: op?.insert, attributes: op?.attributes })
            } else {
                updateOps.push({ insert: op?.insert })
            }
            startIndex += op.insert.length;
        })

        await inputQuill.updateContents(updateOps, 'user')

        setAiResultOutput([]);
        setShowAIResultPopup(false);
        inputQuill.setSelection(startIndex);
        setErrMsg({ errMsg: null });
    };

    let handleTextRewrite = async (resultOutput, className) => {
        let ops = await markdown_converter(resultOutput);
        let startIndex = highlightIndex.start + highlightIndex.length;
        let updateOps = [{ retain: startIndex }]

        ops.map((op) => {
            if (op?.attributes) {
                updateOps.push({ insert: op?.insert, attributes: op?.attributes })
            } else {
                updateOps.push({ insert: op?.insert })
            }
            startIndex += op.insert.length;
        })
        await inputQuill.updateContents(updateOps, 'user')
        setAiResultOutput([]);
        setShowAIResultPopup(false);
        inputQuill.setSelection(startIndex);
        setErrMsg({ errMsg: null });

        // let get = document.getElementsByName('update_content');
        // for(var i=0; i < get.length; i++) {
        //     if (get[i].id != `${className}-cb`) {
        //         get[i].checked = false
        //     }
        // }

        // if (!document.getElementById(`${className}-cb`).checked) {
        //     window.$(`#${className}-cb`).click();
        //     var string =  '\n\n' + replaceString.trim() + '\n\n';
        //     await quill.insertText(highlightIndex.start + highlightIndex.lenght, string, 'user');
        //     var start = Number(highlightIndex.start + highlightIndex.lenght);
        //     end = start + Number(string.length);
        //     // let highlightData = [{ start: start, end: end, input: string }];
        //     // quillContentInsertBlotByContentIndex(quill, 0, highlightData, "start", "end", [{
        //     //     blotName: "span",
        //     //     id: `paraphrase-${start}-${end}`,
        //     //     classes: [`ai_highlight`, `paraphrase-{input}`],
        //     //     classDynamicKeys: [ "", { replaceBy: "{input}", replaceToKey: "input" }],
        //     // }]);
        //     await quill.setSelection(end);
        // }

        // setAiResultOutput([]);
        // setShowAIResultPopup(false);
    };


    // let handleTextParaphrasing = async (resultOutput, className) => {
    //     await highlightTextParaphrasing(inputQuill, resultOutput, highlightIndex, setHighlightIndex, className, selectionStart, selectionLength);
    //     await clearAndCloseBar();
    //     setErrMsg({ errMsg: null });
    // };

    // let handleTextRewrite = async (resultOutput, className) => {

    //     let get = document.getElementsByName('update_content');
    //     for(var i=0; i < get.length; i++) {
    //         if (get[i].id != `${className}-cb`) {
    //             get[i].checked = false
    //         }
    //     }

    //     if (!document.getElementById(`${className}-cb`).checked) {
    //         window.$(`#${className}-cb`).click();
    //         var string =  '\n\n' + resultOutput.trim() + '\n\n';
    //         await inputQuill.insertText(highlightIndex.start + highlightIndex.length, string, 'user');
    //         var start = Number(highlightIndex.start + highlightIndex.length);
    //         end = start + Number(string.length);
    //         await inputQuill.setSelection(end);
    //     }

    //     await clearAndCloseBar();
    // };

    const clearAndCloseBar = async () => {
        await sanitizeResetQuillAndGetContent(inputQuill);
        setAiResultOutput([]);
        setShowAIResultPopup(false);
    }

    const insertIntoQuill = (data) => {
        const elem = document.querySelector(`#dummy-editor #${data.id}`)
        if (elem) elem.innerHTML = data.output;

        let text = document.querySelector(`#dummy-editor`).innerText;
        text = text.replace(/(\r\n|\r|\n){2,}/g, '$1\n');
        text = text.replace(/(\r\n|\r|\n){2,}/g, '$1\n');
        hideRecommendationPanel();
        setResultOutput(text)
        handleHighlights(text);
    }

    const insertIntoQuill2 = (data, word) => {
        const elem = document.querySelector(`#dummy-editor #${data.id}`)
        if (elem) {
            elem.innerHTML = data.phrase.replace(data.word, word);
        }

        let text = document.querySelector(`#dummy-editor`).innerText;
        text = text.replace(/(\r\n|\r|\n){2,}/g, '$1\n');
        text = text.replace(/(\r\n|\r|\n){2,}/g, '$1\n');
        hideRecommendationPanel();
        setResultOutput(text)
    }

    const hideRecommendationPanel = () => {
        setListPhraseRecommendation([]);
        setListWordRecommendation({});
        setToggleRecommendation("");
        document.querySelector("#word_card").style.display = "none";
    }

    let paraPhrasingReport = [];

    if (aiResultOutput?.length != 0) {
        paraPhrasingReport = aiResultOutput || [];
    }

    return (
        <div style={styleAttr} className="col-xl-5 col-lg-4 min-vh-100 bg-light">
            <div className="row text-white theme-background">
                <div className="col-6 my-auto text-start">
                    <p className="fw-semibold mb-0">{aiReportSettings.report} - {aiReportSettings.report_sub}</p>
                </div>
                <div className="col-6 my-2 text-end">
                    <a target="_blank" href={helpURI} className="btn theme-btn py-1 px-2 fs-12px mx-2">Need Help ?</a>
                    <button onClick={(e) => clearAndCloseBar()} className="btn text-white theme-btn2 py-1 px-2 fs-12px mx-2"><span className="mdi mdi-close me-2"></span>Close</button>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="form-floating my-2">
                        <input type="text" value={reportPrompt} onChange={(e) => setReportPrompt(e.target.value)} className="form-control fs-12px" placeholder={`Provide your custom requirement here`} />
                        <label className="fs-12px">Custom Prompt</label>
                    </div>
                </div>
                <div className="col-12">
                    {paraPhrasingReport && paraPhrasingReport.length > 0 && 
                        <div className="text-end">
                            <input style={{ visibility: 'hidden' }} className="form-check-input" name="update_content" id={`paraphrase-${_.kebabCase(paraPhrasingReport[0])}-${0}-cb`} type="checkbox" />
                            <div className="btn theme-btn2 py-1 px-2 fs-12px my-2">
                                <input className="form-check-input" id={`showDeleteCheck`} type="checkbox" checked={showDeleted} onChange={(e) => showDeleteChange(resultOutput, e.target.checked)} />
                                <label className="form-check-label fs-12px fw-semibold ms-1">
                                    <span className="me-2">Show Deleted Text</span>
                                    {highlightContentLoader && <div className="spinner-border sm-spinner m-0" role="status"><span className="visually-hidden">Loading...</span></div>}
                                </label>
                            </div>
                        </div>
                    }
                    <div className="position-relative">
                        <div id="word_card">
                            <ul className="nav nav-tabs shadow-lg">
                                <li className={`nav-item border-end ${toggleRecommendation == 'phrase' ? 'theme-background ' : ''}`}>
                                    <span onClick={(e) => setToggleRecommendation('phrase')} className={`nav-link border-0 rounded-0 cursor-pointer fs-12px ${toggleRecommendation == 'phrase' ? 'text-white' : 'color-theme'}`}>
                                        <span className="me-2">Replace Sentence</span>
                                        {sentenceLoader && <div className="spinner-border sm-spinner m-0" role="status"><span className="visually-hidden">Loading...</span></div>}
                                    </span>
                                </li>
                                <li className={`nav-item border-end ${toggleRecommendation == 'word' ? 'theme-background ' : ''}`}>
                                    <span onClick={(e) => setToggleRecommendation('word')} className={`nav-link border-0 rounded-0 cursor-pointer fs-12px ${toggleRecommendation == 'word' ? 'text-white' : 'color-theme'}`}>Replace Word</span>
                                </li>
                                <li className="nav-item border-end">
                                    <span onClick={(e) => hideRecommendationPanel()} className="nav-link border-0 rounded-0 cursor-pointer fs-12px"> <span className="mdi mdi-close"></span> </span>
                                </li>
                            </ul>
                            {listPhraseRecommendation && 
                                <ul style={{ height: '200px', overflowY: 'scroll' }} className={`dropdown-menu w-100 dropdown-menu w-100 border-0 rounded-0 shadow-lg ${toggleRecommendation == 'phrase' ? 'd-block' : 'd-none'}`}>
                                    {listPhraseRecommendation.length > 0 &&  listPhraseRecommendation.map((phrases) => (
                                        <li 
                                            onClick={(e) => insertIntoQuill(phrases)} 
                                            className="dropdown-item rounded-0 cursor-pointer py-3 border-bottom border-light"
                                        >
                                            <span className="fs-14px text-wrap fw-regular" dangerouslySetInnerHTML={{ __html: phrases.html }}></span>
                                        </li>
                                    ))
                                    }
                                    {listPhraseRecommendation.length == 0 && 
                                        <li><span className="fs-12px dropdown-item rounded-0">Unable to find recommendations</span></li>
                                    }
                                </ul>
                            }
                            {listWordRecommendation?.recommendations &&
                                <ul style={{ height: '200px', overflowY: 'scroll' }} className={`dropdown-menu w-100 dropdown-menu w-100 border-0 rounded-0 shadow-lg ${toggleRecommendation == 'word' ? 'd-block' : 'd-none'}`}>
                                    {listWordRecommendation?.recommendations.length > 0 && listWordRecommendation?.recommendations.map((res, index) => (
                                        <>
                                            <li><span className="fs-12px fw-regular dropdown-item rounded-0 disabled text-wrap">{res.def}</span></li>
                                            <li><hr className="dropdown-divider"></hr></li>
                                            {res.synonyms.map((word) => (
                                                <li onClick={(e) => insertIntoQuill2(listWordRecommendation, word.replaceAll('_', ' '))} className="dropdown-item rounded-0 cursor-pointer">
                                                    <span className="fs-12px fw-medium">{word.replaceAll('_', ' ')}</span>
                                                </li>
                                            ))}
                                            <li><hr className="dropdown-divider"></hr></li>
                                        </>
                                    ))}
                                    {listWordRecommendation?.recommendations.length == 0 &&
                                        <li><span className="fs-12px dropdown-item rounded-0">Unable to find recommendations</span></li>
                                    }
                                </ul>
                            }
                            {!listWordRecommendation?.recommendations && 
                                <ul style={{ height: '200px', overflowY: 'scroll' }} className={`dropdown-menu w-100 dropdown-menu w-100 border-0 rounded-0 shadow-lg ${toggleRecommendation == 'word' ? 'd-block' : 'd-none'}`}>
                                    <li><span className="fs-12px dropdown-item rounded-0">Unable to find recommendations</span></li>
                                </ul>
                            }
                        </div>
                    </div>

                    <div className="bg-white overflow-scroll mb-2" data-gramm="false" translate="no" spellCheck="false" style={{ maxHeight: '60vh', height: '60vh' }} contentEditable={(loader || showDeleted) ? false : true} id="dummy-editor"></div>
                </div>
                <div className="col-12 text-end">
                    {paraPhrasingReport.length == 0 && 
                        <>
                            <button onClick={(e) => checkData(e)} className="btn theme-btn6 py-2 fs-12px m-2" disabled={loader ? true : false}>{loader && <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div>} Generate Content</button>
                            <button onClick={(e) => clearAndCloseBar()} className="btn theme-btn2 py-1 px-2 fs-12px m-2"><span className="mdi mdi-close me-2"></span>Close</button>
                        </>
                    }
                    {paraPhrasingReport && paraPhrasingReport.length > 0 && 
                        <>
                            {aiReportSettings.report != 'Expand Content with AI' && aiReportSettings.report != 'Complete Story with AI' && aiReportSettings.report != 'Summary Generator' && aiReportSettings.report != 'Opposing Arguments' && (
                                <>
                                    <Button1 disabled={loader ? true : false} className="btn theme-btn5 px-2 fs-12px m-1" onClick={(e) => handleTextParaphrasing(resultOutput, `paraphrase-${_.kebabCase(paraPhrasingReport[0])}-0`)}>
                                        <span className="mdi mdi-swap-horizontal me-2"></span>Replace
                                    </Button1>
                                    <Button1 disabled={loader ? true : false} className="btn theme-btn5 px-2 fs-12px m-1" onClick={(e) => handleTextRewrite(resultOutput, `paraphrase-${_.kebabCase(paraPhrasingReport[0])}-0`)}>
                                        <span className="mdi mdi-arrow-left-bottom me-2"></span>Insert Below
                                    </Button1>
                                </>

                            )}
                            {(aiReportSettings.report == 'Expand Content with AI' || aiReportSettings.report == 'Complete Story with AI' || aiReportSettings.report == 'Summary Generator' || aiReportSettings.report == 'Opposing Arguments') && (
                                <Button1  disabled={loader ? true : false} className="btn theme-btn5 px-2 fs-12px m-1" onClick={(e) => handleTextRewrite(resultOutput, `paraphrase-${_.kebabCase(paraPhrasingReport[0])}-0`)}>
                                    <span className="mdi mdi-check me-2"></span>Insert Below
                                </Button1>
                            )}
                            <button onClick={(e) => checkData(e)} className="btn theme-btn4 px-2 fs-12px m-1" disabled={loader ? true : false}> {loader ? <div className="spinner-border sm-spinner" role="status"><span className="visually-hidden">Loading...</span></div> : <span className="mdi mdi-refresh me-2"></span> } Regenerate</button>
                            <button onClick={(e) => clearAndCloseBar()} className="btn theme-btn2 py-1 px-2 fs-12px m-2"><span className="mdi mdi-close me-2"></span>Reject & Close</button>
                        </>
                    }
                </div>
            </div>
        </div>
    )
}